import React from "react";
import SingleServicePage from "../../components/SingleServicePage";
import withLayout from "../../layout";
import {injectIntl} from "react-intl";
import SEO from "../../components/SEO";
import translated from "../../images/portfolio/Translated.mp4";
import translatedThumb from "../../images/portfolio/TRANSLATED_thumbnail.webp";
import audi from "../../images/portfolio/Audi.mp4";
import audiThumb from "../../images/portfolio/AUDI_thumbnail.webp";
import maui from "../../images/portfolio/Mauijim.mp4";
import mauiThumb from "../../images/portfolio/MAUI-JIM_thumbnail.webp";
import drop from '../../images/service_detail_drops/violet.svg';
import {BulletIcon} from "../../images/icons";

/**
 * note that the key we defined in role is needed for building the SingleServicePage
 * component with the right translation strings and media
 * @returns { SingleServicePage }
 * @constructor
 */

const SingleServicePageWrapper = ({intl}) => {
    const IntlTextObj = {
        title: 'analysis.title',
        colorName: 'violetBase',
        color: '#8227EE',
        drop: drop,
        bullet: <BulletIcon/>,
        fromService: 'analysis',
        paragraphs: [
            {title: 'analysis.insights_title', text: 'analysis.insights_desc'},
            {title: 'analysis.business_research_title', text: 'analysis.business_research_desc'}
        ],
        services_detail: ['analysis.detail_1','analysis.detail_2','analysis.detail_3','analysis.detail_4','analysis.detail_5',
                            'analysis.detail_6','analysis.detail_7','analysis.detail_8','analysis.detail_9','analysis.detail_10',
                            'analysis.detail_11','analysis.detail_12'],
        clients: [
            {
                video: audi,
                title: 'portfolio.audi.title',
                poster: audiThumb,
                subtitle: 'portfolio.audi.description',
                to: '/case-study/audi',
            },
            {
                video: maui,
                title: 'portfolio.maui.title',
                poster: mauiThumb,
                subtitle: 'portfolio.maui.description',
                to: '/case-study/maui',
            },
            {
                video: translated,
                title: 'portfolio.translated.title',
                poster: translatedThumb,
                subtitle: 'portfolio.translated.description',
                to: '/case-study/translated',
            }
        ]
    };

    const keywords = intl.formatMessage({id: 'analysis.meta_keywords'}).split(',') || [];

    return <>
            <SEO title={intl.formatMessage({id: 'analysis.meta_title'})}
                 description={intl.formatMessage({id: 'analysis.meta_description'})} keywords={keywords}
                 lang={intl.locale}/>
            <SingleServicePage IntlTextObj={IntlTextObj} intl={intl}/>
        </>
};

const customProps = {
    localeKey: "analysis", // same as file name in src/i18n/translations/your-lang/index.js
};

export default withLayout(customProps)(injectIntl(SingleServicePageWrapper));
